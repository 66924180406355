<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card>
      <b-row
        align-v="center"
        align-h="between"
      >
        <b-col cols="auto">
          {{ $t("new_Ticket_description") }}
        </b-col>
        <b-col
          cols="auto"
          class="mt-2 mt-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'tickets-create' }"
          >
            {{ $t("new_ticket") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="unReadtickets.length !== 0">
      <b-row
        class="mt-2 font-weight-bold"
        align-h="between"
      >
        <b-col cols="auto">
          <h2>{{ $t("open_tickets") }}</h2>
        </b-col>
        <b-col cols="auto">
          <b-form-group
            label-for="search1"
            style="width:200px;"
          >
            <b-form-input
              id="search1"
              v-model="openTicketSearch"
              :placeholder="$t('search')"
              autocomplete="none"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="mt-1"
        align-h="center"
      >
        <b-table
          responsive
          striped
          hover
          sticky-header="1000px"
          no-border-collapse
          :current-page="currentUnreadPage"
          :per-page="per_page"
          :fields="openedfields"
          :items="searchedUnreadTicket"
          sort-by="TheColumnToSort"
          :sort-desc="true"
          :tbody-tr-class="rowClass"
          @row-clicked="onRowSelected"
        >
          <template #cell(status_value)="data">
            <div v-if="data.item.read_by === 'user'">
              <span>{{ $t('operator-response') }}</span>
            </div>
            <div v-if="data.item.read_by === 'operator'">
              <span>{{ $t('your-response') }}</span>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span>{{ getFormattedDate(data.item["date"]) }}</span>
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="primary"
              @click="() => onRowSelected(data.item)"
            >
              {{ $t('details') }}
            </b-button>
          </template>
          <template #cell(updated_at)="data">
            <span :dir=" $i18n.locale ==='fa' ? 'ltr' : ''">{{
              new Date(data.item["updated_at"]).toLocaleString($i18n.locale)
            }}</span>
          </template>
          <template #cell(title)="data">
            <span>{{
              data.item.title.length > 30
                ? data.item.title.substring(0, 30) + "..."
                : data.item.title
            }}</span>
          </template>
          <template #cell(context)="data">
            <span>{{
              data.item.context.length > 20
                ? data.item.context.substring(0, 20) + "..."
                : data.item.context
            }}</span>
          </template>
          <template #cell(details)="data">
            <b-button
              variant="outline-primary"
              @click="() => onRowSelected(data.item)"
            >{{ $t("more_details") }}</b-button>
          </template>
        </b-table>
        <div v-if="!searchedUnreadTicket.length">
          {{ $t('no_item_exsist') }}
        </div>
        <b-pagination
          v-if="searchedUnreadTicket.length > per_page"
          v-model="currentUnreadPage"
          :total-rows="searchedUnreadTicket.length"
          :per-page="per_page"
          align="fill"
          size="sm"
          class="mt-2 mb-1"
        />
      </b-row>
    </b-card>

    <b-overlay
      :show="readTicketShow"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card>
        <b-row
          class="mt-2 font-weight-bold"
          align-h="between"
        >
          <b-col cols="auto">
            <h2>{{ $t("closed_tickets") }}</h2>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label-for="search2"
              style="width:200px;"
            >
              <b-form-input
                id="search2"
                v-model="closedTicketSearch"
                type="text"
                :placeholder="$t('search')"
                @input="closedSearch"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          class="mt-1 readTicket"
          align-h="center"
        >
          <b-table
            responsive
            striped
            sticky-header="1000px"
            hover
            no-border-collapse
            :fields="closedfields"
            :items="readTickets"
            :tbody-tr-class="rowClass"
            :sort-compare="()=>false"
            @row-clicked="onRowSelected"
            @sort-changed="sortChanged"
          >
            <template #cell(status_value)="data">
              <span
                :class="data.item.status_id == 1 ? 'text-green' : 'text-red'"
              >{{ data.item["status_value"] }}
              </span>
            </template>
            <template #cell(updated_at)="data">
              <span>{{
                new Date(data.item["updated_at"]).toLocaleString($i18n.locale)
              }}
              </span>
            </template>
            <template #cell(title)="data">
              <span>{{
                data.item.title.length > 15
                  ? data.item.title.substring(0, 15) + "..."
                  : data.item.title
              }}</span>
            </template>
            <template #cell(context)="data">
              <span>{{
                data.item.context.length > 20
                  ? data.item.context.substring(0, 20) + "..."
                  : data.item.context
              }}</span>
            </template>
            <template #cell(created_at)="data">
              <span>{{ getFormattedDate(data.item["date"]) }}</span>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="primary"
                @click="() => onRowSelected(data.item)"
              >
                {{ $t('details') }}
              </b-button>
            </template>
            <template #cell(details)="data">
              <b-button
                variant="outline-primary"
                @click="() => onRowSelected(data.item)"
              >{{ $t("more_details") }}</b-button>
            </template>
          </b-table>
          <div v-if="!readTickets.length">
            {{ $t('no_item_exsist') }}
          </div>
          <b-pagination
            v-if="totalReadTickets > per_page"
            v-model="page"
            :total-rows="totalReadTickets"
            :per-page="per_page"
            align="fill"
            size="sm"
            class="mt-2 mb-1"
          />
        </b-row>
      </b-card>
    </b-overlay>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormGroup,
  BPagination,
  BFormInput,
} from 'bootstrap-vue'
import TicketService from '@/services/apis/tickets'
import moment from '@/libs/moment'

const { getReadTickets, getUnreadTickets } = new TicketService()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormGroup,
    BPagination,
    BFormInput,
  },

  data() {
    return {
      show: true,
      readTicketShow: false,
      unReadtickets: [],
      readTickets: [],
      unReadTicketPagination: {},
      currentUnreadPage: 1,
      per_page: 10,
      page: 1,
      totalReadTickets: 0,
      openTicketSearch: '',
      closedTicketSearch: '',
      timer: null,
      ticketNameSort: '',
      ticketTypeSort: '',
    }
  },

  computed: {
    searchedUnreadTicket() {
      return this.unReadtickets.filter(item => JSON.stringify(item).toLowerCase().includes(this.openTicketSearch.toLowerCase()))
    },
    openedfields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'title', label: this.$t('title') },
        { key: 'status_value', label: this.$t('status') },
        { key: 'created_at', label: this.$t('date') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    closedfields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'created_at', label: this.$t('date') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
  },

  watch: {
    async page() {
      await this.getReadTickets()
    },
    openTicketSearch() {
      this.currentUnreadPage = 1
    },
    readTicketShow() {
      setTimeout(() => {
        this.readTicketShow = false
      }, 6000)
    },
  },

  async created() {
    await this.getReadTickets()
    await this.getUnreadTickets()
  },

  methods: {
    onRowSelected(item) {
      this.$router.push(`/${this.$i18n.locale}/tickets/${item.id}`)
    },

    getReadTickets() {
      this.readTicketShow = true
      this.show = true
      return getReadTickets({
        page: this.page,
        per_page: this.per_page,
        search: this.closedTicketSearch,
        sort: this.ticketNameSort,
        sort_type: this.ticketTypeSort,
      })
        .then(({ data }) => {
          this.readTickets = data.results.data
          this.totalReadTickets = data.results.total
        })
        .finally(() => {
          this.readTicketShow = false
          this.show = false
        })
    },

    getUnreadTickets() {
      this.show = true
      return getUnreadTickets()
        .then(({ data }) => {
          this.unReadtickets = data.results
        })
        .finally(() => {
          this.show = false
        })
    },

    closedSearch() {
      clearTimeout(this.timer)

      this.timer = setTimeout(async () => {
        await this.getReadTickets()
      }, 1500)
    },

    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.user_read === '0') {
          return 'unread-background-color'
        }
      }
      return null
    },

    async sortChanged(e) {
      this.readTicketShow = true
      if (e.sortBy === 'category_title') {
        this.ticketNameSort = 'ticket_category_id'
      } else {
        this.ticketNameSort = e.sortBy
      }
      if (e.sortDesc) {
        this.ticketTypeSort = 'DESC'
      } else {
        this.ticketTypeSort = 'ASC'
      }
      await this.getReadTickets()
      setTimeout(() => {
        this.readTicketShow = false
      }, 500)
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
  },
}
</script>

<style>
.text-red {
  color: red;
  font-weight: bold;
}

.text-green {
  color: green;
  font-weight: bold;
}

.unread-background-color {
  background-color: #d0d2d6 !important;
}
</style>
